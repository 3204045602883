import { Component } from 'react';
import Menu from './Menu.js';
import Intl from './Intl.js';
import './App.css';


const guideStyle = {
  marginTop: '25px',
  marginBottom: '25px',
  borderColor: 'rgb(222, 222, 222)',
  borderWidth: '2px',
  backgroundColor: 'rgb(230, 230, 230)',
  fontSize: 'calc(12px + 3vmin)',
  borderRadius: '5px',
  minHeight: '44px',
  boxShadow: '0px 2px 1px -1px rgba(0,0,0,20%), 0px 1px 1px 0px rgba(0,0,0,14%), 0px 1px 3px 0px rgba(0,0,0,12%)',
  cursor: 'pointer'
};

const imageStyle = {
  width: '200px',
  height: '200px'
};


class App extends Component {
  
  constructor() {
    super();
    
    this.state = {
      language: Intl.selectedLanguage
    }
  }
  
  componentDidMount() {
    Intl.setLanguageInitially(language => {
      if (language !== this.state.language)
        this.setState(Object.assign({}, this.state, {language: language}));
    });
  }
  
  renderGuide(guide, i) {
    const guideName = Intl.tl(guide.names);
    return (
      <div key={i} style={guideStyle}>
        <div><img style={imageStyle} alt={guideName + ' by Walking'} src={'/images/' + guide.thumbnail} /></div>
        <div className='guideTitle'>{guideName}</div>
      </div>
    );
  }
  
  render() {
    let page = window.location.pathname.replaceAll('/', '');
    if (!['home', 'share', 'contact', 'aboutus'].includes(page)) page = 'home';
    
    const guides = [
      {names: ['Zurich', 'Zürich'], thumbnail:'zurich.png'},
      {names: ['Zurich', 'Zürich'], thumbnail:'zurich.png'},
    ];
    
    return (
      <div id='App' className='App'>
        <header className='App-header'>
          <img style={{maxWidth: '400px', width: '100%', height: 'auto'}} alt='By Walking' src='/hbywalking.png' />
        </header>
        
        <Menu language={this.state.language} onSelect={lang => {Intl.setLanguage(lang); this.setState(Object.assign({}, this.state, {language:lang}))}}>
          {page === 'home' &&
            guides.map((x,i) => {return this.renderGuide(x, i)})
          }
          
          {page === 'share' &&
            <div>{Intl.t('Share the love', 'Zeig deine Liebe')}</div>
          }
          
          {page === 'contact' &&
            <div>{Intl.t('Get in touch', 'Schreib uns')}</div>
          }
          
          {page === 'aboutus' &&
            <div>{Intl.t('Who we are', 'Wer wir sind')}</div>
          }
        </Menu>
      </div>
    ); 
  }
}

/*
        <Menu selectedLanguage={this.state.language} onSelectLanguage={lang => {Intl.setLanguage(lang); this.setState(Object.assign({}, this.state, {language:lang}))}} pageWrapId='page-wrap' outerContainerId='App' />
        
        <main id='page-wrap'>
          {page === 'home' &&
            guides.map((x,i) => {return this.renderGuide(x, i)})
          }
          
          {page === 'share' &&
            <div>{Intl.t('Share the love', 'Zeig deine Liebe')}</div>
          }
          
          {page === 'contact' &&
            <div>{Intl.t('Get in touch', 'Schreib uns')}</div>
          }
          
          {page === 'aboutus' &&
            <div>{Intl.t('Who we are', 'Wer wir sind')}</div>
          }
        </main>
*/
export default App;